import React, { useState, useRef, useEffect } from 'react';
import md5 from 'md5';
import './MedicalTest.css'; // Importing the CSS file for styling
import checkMark from './images/check-mark.png';
import rightArrow from './images/right.png';

function MedicalTestPage({setIsFormDirty}) {
    const [currentSection, setCurrentSection] = useState('questionnaire'); // Start with the questionnaire section
    const [selectedTest, setSelectedTest] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [analysisResult, setAnalysisResult] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupStage, setPopupStage] = useState('initial'); // New state to manage popup stages
    const [questionnaire, setQuestionnaire] = useState(null);
    const [results, setResults] = useState({});
    const [completedTests, setCompletedTests] = useState({})
    const [patientInfo, setPatientInfo] = useState({});
    const [gender, setGender] = useState();
    const [isPregnant, setIsPregnant] = useState();
    const [voucherNr, setVoucherNr] = useState();
    const [scanheading, setScanHeading] = useState('')
    const [nurseEmail, setNurseEmail] = useState();
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);
    const [retryCount, setRetryCount] = useState(0);


    const Rid = md5(String(Date.now()))

    useEffect(() => {
        // Retrieve patient info from local storage
        const storedPatientInfo = window.localStorage.getItem('patientInfo');
        if (storedPatientInfo) {
            setPatientInfo(JSON.parse(storedPatientInfo));
            // Include patient info in the results object
            setResults(prevResults => ({
                ...prevResults,
                patientInfo: JSON.parse(storedPatientInfo),
            }));
        }

        const storageVoucherNR = window.localStorage.getItem('voucherNr');
        setVoucherNr(JSON.parse(storageVoucherNR));

        const storageNurseEmail = window.localStorage.getItem('nurseEmail');
        setNurseEmail(JSON.parse(storageNurseEmail));

    }, []);

    const useFrontCamera = window.innerWidth >= 800;

    const startCamera = () => {
        const constraints = {
            video: {
                facingMode: useFrontCamera ? "user" : "environment",
            },
        };

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                videoRef.current.srcObject = stream;
            })
            .catch((error) => {
                console.error("Error accessing the camera", error);
            });
    };

    const takePhoto = () => {
        const context = canvasRef.current.getContext("2d");
        context.drawImage(videoRef.current, 0, 0, 640, 480);
        const dataURL = canvasRef.current.toDataURL("image/jpeg");
        setImageData(dataURL);
        setPopupStage('photoTaken');
    };

    const analyzeImage = async () => {
        const response = await fetch(`/${selectedTest}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ image: imageData }),
        });

        const data = await response.json();

        // Parse the analysis result if it's a JSON string
        let parsedResult;
        try {
            parsedResult = typeof data.analysis === 'string' ? JSON.parse(data.analysis) : data.analysis;
        } catch (e) {
            console.error('Error parsing analysis result:', e);
            parsedResult = data.analysis; // Fall back to raw data if parsing fails
        }

        setAnalysisResult(parsedResult);
        setResults(prevResults => ({ ...prevResults, [selectedTest]: parsedResult }));
        setCompletedTests(prevResults => ({ ...prevResults, [selectedTest]: parsedResult }));

        setPopupStage('analysisDone');
        
    };

    const openPopup = (test) => {
        setSelectedTest(test);
        setIsPopupOpen(true);
        setPopupStage('initial');
        setQuestionnaire(null);
        if (test.startsWith('analyze')) {
            startCamera();
        }
    };

    const openQuestionnairePopup = (questions, type) => {
        if (type === 'questionnaire_General') {
            questions["GEN-pid"].A = patientInfo.idNumber || '';
            questions["GEN-tim"].A = Math.floor(Date.now() / 1000); // Current Unix timestamp
            questions["GEN-rid"].A = Rid; // Generate a unique resultsID using md5
        }
    
        // Exclude the fields from showing in the pop-up
        const filteredQuestions = { ...questions };
        delete filteredQuestions["GEN-pid"];
        delete filteredQuestions["GEN-tim"];
        delete filteredQuestions["GEN-rid"];
    
        setQuestionnaire(filteredQuestions);
        setSelectedTest(type);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setImageData(null);
        setAnalysisResult('');
        setPopupStage('initial');
        setQuestionnaire(null);
    };

    const handleConfirm = () => {
        closePopup();
    };


    useEffect(() => {
        console.log("Gender updated:", gender);
        console.log("Is pregnant updated:", isPregnant);
    }, [gender, isPregnant]);


    const handleSubmitQuestionnaire = () => {
        const questionnaireResults = Object.keys(questionnaire).reduce((acc, key) => {
            const questionObj = questionnaire[key];
            let answer = questionObj.A;
    
            if (!answer) {
                if (questionObj.T === 'Y or N') {
                    answer = document.querySelector(`input[name="question-${key}"]:checked`)?.value || '';
                } else if (typeof questionObj.T === 'string') {
                    answer = document.querySelector(`input[name="question-${key}"]`)?.value || '';
                } else if (Array.isArray(questionObj.T)) {
                    answer = document.querySelector(`select[name="question-${key}"]`)?.value || '';
                }
            }
    
            // Update gender and pregnancy status based on the answers
            if (key === "GEN-gen") {
                setGender(answer);
            }
            if (key === "OBS-pre") {
                setIsPregnant(answer);
            }
            
            return { ...acc, [key]: { Q: questionObj.Q, A: answer } };
        }, {});
    
         // Add GEN-pid, GEN-tim, and GEN-rid only to the "questionnaire_General" questionnaire
        if (selectedTest === 'questionnaire_General') {
            questionnaireResults["GEN-pid"] = {
                Q: "patientID",
                A: patientInfo.idNumber || ''
            };
            questionnaireResults["GEN-tim"] = {
                Q: "Time",
                A: Math.floor(Date.now() / 1000) // Current Unix timestamp
            };
            questionnaireResults["GEN-rid"] = {
                Q: "resultsID",
                A: md5(String(Date.now())) // Generated resultsID
            };
            questionnaireResults["GEN-cel"] = {
                A: patientInfo.primaryCell // Generated resultsID
            };
            questionnaireResults["GEN-sms"] = {
                A: "N" // Generated resultsID
            };
        }
        
        setCompletedTests(prevResults => ({ ...prevResults, [selectedTest]: questionnaireResults }));
        setResults(prevResults => ({ ...prevResults, [selectedTest]: questionnaireResults }));
        closePopup();
    };
    

    const shouldDisplayPregnancyQuestionnaire = () => {
        if (gender === "Female" && isPregnant === "Y"){
            return true;
        }
        else{
            return false;
        } 
    };
    

    const processAnalyzeResults = (results) => {
        const updatedResults = { ...results };
    
        Object.keys(updatedResults).forEach(key => {
            if (key.startsWith('analyze_') && typeof updatedResults[key] === 'object') {
                const nestedObject = updatedResults[key];
                
                // Merge the nested object into the updatedResults
                Object.keys(nestedObject).forEach(nestedKey => {
                    updatedResults[nestedKey] = nestedObject[nestedKey];
                });
    
                // Remove the original analyze_* key
                delete updatedResults[key];
            }
        });
    
        return updatedResults;
    };


    const processQuestionnaireResults = (results) => {
        const updatedResults = { ...results };
    
        Object.keys(updatedResults).forEach(key => {
            if (key.startsWith('questionnaire_') && typeof updatedResults[key] === 'object') {
                const nestedObject = updatedResults[key];
                
                // Merge the nested object into the updatedResults
                Object.keys(nestedObject).forEach(nestedKey => {
                    updatedResults[nestedKey] = nestedObject[nestedKey];
                });
    
                // Remove the original analyze_* key
                delete updatedResults[key];
            }
        });
    
        return updatedResults;
    };


    const handleSubmit = async () => {
        // Process the results
        setIsFormDirty(false);
        
        setIsSubmitting(true); // Start the loading process
        setSubmitStatus('loading'); // Set the status to loading
        setRetryCount(0); // Reset retry count


        const processedResults = processAnalyzeResults(results);
        const finalProcessedResults = processQuestionnaireResults(processedResults);
    
        // Extract patientInfo and scanResults
        const { patientInfo, ...scanResults } = finalProcessedResults;
    
        // Construct the final object to save to local storage
        const finalResults = {
            patientInfo,
            voucher: voucherNr,
            scanResults
        };
    
        // Save the final object to local storage
        window.localStorage.setItem('finalResults', JSON.stringify(finalResults));
        const data = {scanResults}
    
        // Construct the payload for the API request
        const apiPayload = {
            nurseEmail: nurseEmail,
            voucherNumber: voucherNr,
            resultsObjectString: JSON.stringify(data),
            patientName: patientInfo?.name || '',
            patientSurname: patientInfo?.surname || '',
            patientIdNum: patientInfo?.idNumber || '',
            patientEmailAddress: patientInfo?.email || '',
            patientCellNum: '',
            resultsId: Rid || '', // If you have a way to generate a unique ID
            primaryCellNum: patientInfo?.primaryCell, 
            secondaryCellNum: patientInfo?.secondaryCell 
        };

        console.log("payload ============" + JSON.stringify(apiPayload))
    
        try {
            // Send the POST request to the API
            //const url = 'https://preview.wem.io/53398/webservices/MesApp/ScreeningCompleted' // TESTING ENV
            const url = "https://fio-enterprise-information-system-fio-medescreen.live.wem.io/webservices/MesApp/ScreeningCompleted"
            

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiPayload),
            });
    
            console.log(response);
            
            // Log success or process the response
            const responseData = await response.json();
            console.log('API response:', responseData);
            console.log("results ===== " + results);

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            setSubmitStatus('success'); // Set the status to success
            setTimeout(() => {
                window.location.href = '/results'; // Redirect to the results page after 3 seconds
            }, 3000);

            // Redirect to the Results page
            //window.location.href = '/results';

        } catch (error) {
            console.error('Failed to send data to API:', error);
            setRetryCount(prevCount => prevCount + 1);
                if (retryCount < 2) {
                    setSubmitStatus('error'); // Set the status to error for retry
                } else {
                    setSubmitStatus('failed'); // After 3 attempts, indicate to redo the test
                }
            //window.location.href = '/results';
        }
    }; 
    

    const questionnaire_General = {
        "GEN-pid": {
            "Q": "patientID",
            "T": "Text"
        },
        "GEN-tim": {
            "Q": "Time",
            "T": "Number"
        },
        "GEN-rid": {
            "Q": "resultsID",
            "T": "Text"
        },
        "GEN-age": {
            "Q": "Age?",
            "T": "Number"
        },
        "GEN-rac": {
            "Q": "Race?",
            "T": ["Black African","Coloured","Indian","White","Other"]
        },
        "GEN-gen": {
            "Q": "Gender?",
            "T": ["Male", "Female"]
        },
        "GEN-dep": {
            "Q": "Dependant?",
            "T": "Y or N"
        },
        "OBS-pre": {
            "Q": "Are you pregnant?",
            "T": "Y or N"
        }
    }

    const questionnaire_HIV ={
        "HIV-kno": {
            "Q": "HIV status known?",
            "T": "Y or N"
        },
        "HIV-tre": {
            "Q": "HIV treatment?",
            "T": "Y or N"
        },
        "HIV-pos": {
            "Q": "HIV positive?",
            "T": "Y or N"
        },
        "HIV-tst": {
            "Q": "HIV Test Outcome? ",
            "T": "Y or N"
        }
    }

    const questionnaire_Health = {
        "GEN-hpt": {
            "Q": "Hypertensive?",
            "T": "Y or N"
        },
        "GEN-htt": {
            "Q": "Hypertension treatment?",
            "T": "Y or N"
        },
        "GEN-dbm": {
            "Q": "Diabetes mellitus?",
            "T": "Y or N"
        },
        "GLU-fas": {
            "Q": "Fasting Glucose?",
            "T": "Y or N"
        },
        "GEN-hea": {
            "Q": "Headaches?",
            "T": "Y or N"
        },
        "GEN-smo": {
            "Q": "Smoking?",
            "T": "Y or N"
        },
        "GEN-fat": {
            "Q": "Unexplained fatigue or tiredness?",
            "T": "Y or N"
        },
        "OED-per": {
            "Q": "Oedema - peripheral?",
            "T": "Y or N"
        },
        "OED-pul": {
            "Q": "Oedema - pulmonary?",
            "T": "Y or N"
        },
        "GEN-urb": {
            "Q": "Urine blood?",
            "T": "Y or N"
        },
        "GEN-fhi": {
            "Q": "Family history of IHD?",
            "T": "Y or N"
        },
        "GEN-sob": {
            "Q": "Shortness of breath?",
            "T": "Y or N"
        },
        "GEN-tbd": {
            "Q": "TB diagnosed?",
            "T": "Y or N"
        },
        "GEN-ctb": {
            "Q": "Any contact with anyone suffering from TB in the past year?",
            "T": "Y or N"
        },
        "GEN-prc": {
            "Q": "Productive cough for 3 weeks or more?",
            "T": "Y or N"
        },
        "GEN-cbl": {
            "Q": "Coughing blood?",
            "T": "Y or N"
        },
        "GEN-uwl": {
            "Q": "Unexplained weight loss?",
            "T": "Y or N"
        },
        "GEN-foc": {
            "Q": "Any fever or chills or sweating at night for no obvious reason?",
            "T": "Y or N"
        },
        "GEN-pob": {
            "Q": "Pain on breathing?",
            "T": "Y or N"
        },
        "GEN-ccp": {
            "Q": "Current chest pain?",
            "T": "Y or N"
        }
    };

    const questionnaire_Pregnancy = {
        "OBS-lnp": {
            "Q": "Date of last normal period?",
            "T": "yyyy/mm/dd"
        },
        "OBS-ppr": {
            "Q": "Planned pregnancy?",
            "T": "Y or N"
        },
        "OBS-con": {
            "Q": "Currently using contraception?",
            "T": "Y or N"
        },
        "OBS-cap": {
            "Q": "Planned contraception use after the pregnancy?",
            "T": ["Nothing","Oral","Injectable","Sterilisation","Other"]
        },
        "OBS-npp": {
            "Q": "Total number of previous pregnancies?",
            "T": "Number"
        },
        "OBS-npc": {
            "Q": "Number of previous complicated pregnancies?",
            "T": "Number"
        },
        "OBS-prs": {
            "Q": "Previous reproductive tract surgery (e.g. Caesarean section or cerclage)?",
            "T": "Y or N"
        },
        "OBS-lcb": {
            "Q": "Number of live children born?",
            "T": "Number"
        },
        "OBS-nsb": {
            "Q": "Number of stillbirths?",
            "T": "Number"
        },
        "OBS-nab": {
            "Q": "Number of abortions or miscarriages?",
            "T": "Number"
        },
        "OBS-npl": {
            "Q": "Number of times pre-term labour?",
            "T": "Number"
        },
        "OBS-nvd": {
            "Q": "Nature of vaginal discharge?",
            "T": ["Normal","Bloody","Offensive","Bloody and offensive"]
        },
        "OBS-svb": {
            "Q": "Spotting or vaginal bleeding?",
            "T": "Y or N"
        },
        "OBS-rbm": {
            "Q": "Regular baby movements?",
            "T": "Y or N"
        },
        "OBS-blf": {
            "Q": "Baby last felt?",
            "T": ["Under 4 hours ago","Between 4 and 8 hours ago","Between 8 and 24 hours ago","More than 24 hours ago"]
        },
        "OBS-buu": {
            "Q": "Burning urine?",
            "T": "Y or N"
        },
        "OBS-hbu": {
            "Q": "Heart burn?",
            "T": "Y or N"
        },
        "OBS-sys": {
            "Q": "Syphilis status?",
            "T": ["Unknown","Tested and positive","Tested and negative"]
        },
        "OBS-flh": {
            "Q": "Fundal height? (cm)",
            "T": "Number"
        }
    }

    const questionnaire_SRQ20 = {
        "SRQ20-01": {
            "Q": "Do you often have headaches (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-02": {
            "Q": "Is your appetite poor (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-03": {
            "Q": "Do you sleep badly (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-04": {
            "Q": "Are you easily frightened (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-05": {
            "Q": "Do your hands shake (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-06": {
            "Q": "Do you feel nervous, tense or worried (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-07": {
            "Q": "Is your digestion poor (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-08": {
            "Q": "Do you have trouble thinking clearly (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-09": {
            "Q": "Do you feel unhappy (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-10": {
            "Q": "Do you cry more than usual (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-11": {
            "Q": "Do you find it difficult to enjoy your daily activities (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-12": {
            "Q": "Do you find it difficult to make decisions (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-13": {
            "Q": "Is your daily work suffering (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-14": {
            "Q": "Are you unable to play a useful part in life (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-15": {
            "Q": "Have you lost interest in things (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-16": {
            "Q": "Do you feel that you are a worthless person (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-17": {
            "Q": "Has the thought of ending your life been on your mind (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-18": {
            "Q": "Do you feel tired all the time (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-19": {
            "Q": "Do you have uncomfortable feelings in your stomach (past 30 days)?",
            "T": "Y or N"
        },
        "SRQ20-20": {
            "Q": "Are you easily tired (past 30 days)?",
            "T": "Y or N"
        }

    };


    // Function to get button style
    const getButtonStyle = (test) => {
        if (completedTests[test]) {
            return {
                textDecoration: 'line-through',
                backgroundColor: '#C4371B'
            };
        }
        return {};
    };


    const renderQuestion = (questionKey, questionObj, index) => {
        const { Q, T } = questionObj;
    
        switch (typeof T) {
            case 'string':
                if (T === 'Y or N') {
                    return (
                        <div className='radio-questions' key={index}>
                            <p>{Q}</p>
                            <label>
                                <input type="radio" name={`question-${questionKey}`} value="Y" /> Yes
                            </label>
                            <label>
                                <input type="radio" name={`question-${questionKey}`} value="N" /> No
                            </label>
                        </div>
                    );
                } else if (T === 'Text' || T === 'Number') {
                    return (
                        <div className='text-questions' key={index}>
                            <p>{Q}</p>
                            <input type={T} name={`question-${questionKey}`} />
                        </div>
                    );
                }
                break;
    
            case 'object':
                return (
                    <div className='drop-dwn-questions' key={index}>
                        <p>{Q}</p>
                        <select name={`question-${questionKey}`}>
                            {T.map((option, i) => (
                                <option key={i} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                );
            
            default:
                return null;
        }
    };
    
    

    return (
        <div className="medical-test-page">

            <div id="image_container" className='img-container'>
                <img
                    id="image"
                    className='img'
                    src="http://medescreen.co.za/wp-content/uploads/2023/09/Medescreen-Logo-Green.png"
                    alt="Medescreen Logo"
                />
            </div>

            {currentSection === 'questionnaire' ? (
            <div className='questionnaire-section'>

                <h1>Medical Questionnaire</h1>
                
                <div id="btn_container" className='questionnaire-btn-container'>

                    {/* Other questionnaire buttons */}
                    <button 
                        className={`questionnaire-btn ${results.questionnaire_General ? 'completed' : ''}`} 
                        style={getButtonStyle('questionnaire_General')} 
                        onClick={() => {openQuestionnairePopup(questionnaire_General, 'questionnaire_General'); setIsFormDirty(true);}}
                    >
                        General Questionnaire
                    </button>
                    
                    <button 
                        className={`questionnaire-btn ${results.questionnaire_HIV ? 'completed' : ''}`} 
                        style={getButtonStyle('questionnaire_HIV')} 
                        onClick={() => {openQuestionnairePopup(questionnaire_HIV, 'questionnaire_HIV'); setIsFormDirty(true);}}
                    >
                        HIV Questionnaire
                    </button>

                    {shouldDisplayPregnancyQuestionnaire() && (
                        <button 
                            className={`questionnaire-btn ${results.questionnaire_Pregnancy ? 'completed' : ''}`} 
                            style={getButtonStyle('questionnaire_Pregnancy')} 
                            onClick={() => {openQuestionnairePopup(questionnaire_Pregnancy, 'questionnaire_Pregnancy'); setIsFormDirty(true);}}
                        >
                            Pregnancy Questionnaire
                        </button>
                    )}

                    <button 
                        className={`questionnaire-btn ${results.questionnaire_Health ? 'completed' : ''}`} 
                        style={getButtonStyle('questionnaire_Health')} 
                        onClick={() => {openQuestionnairePopup(questionnaire_Health, 'questionnaire_Health'); setIsFormDirty(true);}}
                    >
                        Health Questionnaire
                    </button>

                    <button 
                        className={`questionnaire-btn ${results.questionnaire_SRQ20 ? 'completed' : ''}`} 
                        style={getButtonStyle('questionnaire_SRQ20')} 
                        onClick={() => {openQuestionnairePopup(questionnaire_SRQ20, 'questionnaire_SRQ20'); setIsFormDirty(true);}}
                    >
                        SRQ20 Questionnaire
                    </button>
                    
                </div>

                <div className='questionnaire-submit-container'>
                    <button
                        className="questionnaire-submit-btn"
                        onClick={() => setCurrentSection('scans')}
                        //disabled={!(results.questionnaire_Health && results.questionnaire_SRQ20)} // Disable until both questionnaires are completed
                    >
                        Next
                    </button>
                </div>

            </div>
            
            ) : (
            
            <div className='scan-section'>

                <h1>Physical Screening Tests</h1>

                <div id="btn_container" className='scan-btn-container'>
                    <button 
                    className={`snap-btn ${results.analyze_urine_analysis ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_urine_analysis')} 
                    onClick={() => { openPopup('analyze_urine_analysis'); setScanHeading('Urine Analysis'); setIsFormDirty(true);}}
                    >
                    Urine Analysis
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_height ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_height')} 
                    onClick={() => { openPopup('analyze_height'); setScanHeading('Height Measurement'); setIsFormDirty(true); }}
                    >
                    Height Measurement
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_waist ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_waist')} 
                    onClick={() => { openPopup('analyze_waist'); setScanHeading('Waist Measurement'); setIsFormDirty(true); }}
                    >
                    Waist Measurement
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_hip ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_hip')} 
                    onClick={() => { openPopup('analyze_hip'); setScanHeading('Hip Circumference'); setIsFormDirty(true);}}
                    >
                    Hip Circumference
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_weight ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_weight')} 
                    onClick={() => { openPopup('analyze_weight'); setScanHeading('Weight Measurement'); setIsFormDirty(true);}}
                    >
                    Weight Measurement
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_temperature ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_temperature')} 
                    onClick={() => { openPopup('analyze_temperature'); setScanHeading('Temperature Analysis'); setIsFormDirty(true);}}
                    >
                    Temperature Analysis
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_fev_lung_function ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_fev_lung_function')} 
                    onClick={() => { openPopup('analyze_fev_lung_function'); setScanHeading('Lung Function (FEV1)'); setIsFormDirty(true);}}
                    >
                    Lung Function (FEV1)
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_pef_lung_function ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_pef_lung_function')} 
                    onClick={() => { openPopup('analyze_pef_lung_function'); setScanHeading('Lung Function (PEF)'); setIsFormDirty(true);}}
                    >
                    Lung Function (PEF)
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_blood_pressure ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_blood_pressure')} 
                    onClick={() => { openPopup('analyze_blood_pressure'); setScanHeading('Blood Pressure'); setIsFormDirty(true);}}
                    >
                    Blood Pressure
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_pulse_oximeter ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_pulse_oximeter')} 
                    onClick={() => { openPopup('analyze_pulse_oximeter'); setScanHeading('Pulse Oximeter'); setIsFormDirty(true);}}
                    >
                    Pulse Oximeter
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_ecg ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_ecg')} 
                    onClick={() => { openPopup('analyze_ecg'); setScanHeading('ECG'); setIsFormDirty(true);}}
                    >
                    ECG
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_haemoglobin_blood ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_haemoglobin_blood')} 
                    onClick={() => { openPopup('analyze_haemoglobin_blood'); setScanHeading('Blood Haemoglobin'); setIsFormDirty(true);}}
                    >
                    Blood Haemoglobin
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_glucose_blood ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_glucose_blood')} 
                    onClick={() => { openPopup('analyze_glucose_blood'); setScanHeading('Blood Glucose'); setIsFormDirty(true);}}
                    >
                    Blood Glucose
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_cholesterol_blood ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_cholesterol_blood')} 
                    onClick={() => { openPopup('analyze_cholesterol_blood'); setScanHeading('Blood Cholesterol'); setIsFormDirty(true);}}
                    >
                    Blood Cholesterol
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_uric_blood ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_uric_blood')} 
                    onClick={() => { openPopup('analyze_uric_blood'); setScanHeading('Blood Uric Acid'); setIsFormDirty(true);}}
                    >
                    Blood Uric Acid
                    </button>

                    <button 
                    className={`snap-btn ${results.analyze_general ? 'completed' : ''}`} 
                    style={getButtonStyle('analyze_general')} 
                    onClick={() => { openPopup('analyze_general'); setScanHeading('General Analysis'); setIsFormDirty(true);}}
                    >
                    General Analysis
                    </button>

                </div>
                {/* Submit button to finalize */}
                <div className='scan-submit-container'>
                    <button className="scan-cancel-btn" onClick={() => setCurrentSection('questionnaire')}>Back</button>
                    <button
                        className="scan-submit-btn"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        Submit
                    </button>

{/*                     <button className="scan-submit-btn" onClick={handleSubmit}>Submit</button>
 */}                </div>
            </div>
            )}

            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-content">
                        {questionnaire ? (
                            <div>
                                <img
                                    className='popup-logo'
                                    src="http://medescreen.co.za/wp-content/uploads/2023/09/Medescreen-Logo-Green.png"
                                    alt="Medescreen Logo"
                                />
                                <h2>{selectedTest === 'questionnaire_General' ? 'General Questionnaire' : selectedTest === 'questionnaire_HIV' ? 'HIV Questionnaire' : selectedTest === 'questionnaire_Pregnancy' ? 'Pregnancy Questionnaire' : selectedTest === 'questionnaire_Health' ? 'Health Questionnaire' : 'SRQ20 Questionnaire'}</h2>
                                <div className='questions-container'>
                                    {Object.keys(questionnaire).map((key, index) =>
                                        renderQuestion(key, questionnaire[key], index)
                                    )}
                                </div>
                                <div className='questions-btn-container'>
                                    <button className="questions-cancel-btn" onClick={closePopup}>Cancel</button>
                                    <button className="questions-submit-btn" onClick={handleSubmitQuestionnaire}>Submit</button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <img
                                    className='popup-logo'
                                    src="http://medescreen.co.za/wp-content/uploads/2023/09/Medescreen-Logo-Green.png"
                                    alt="Medescreen Logo"
                                />
                                <h1>{scanheading}</h1>
                                <video ref={videoRef} className='display-container' autoPlay style={{ display: popupStage === 'initial' ? 'block' : 'none' }}></video>
                                {imageData && <img src={imageData} className='captured-container' alt="Captured" />}
                                {popupStage === 'initial' &&
                                    <>
                                        <p> Take a photo of the readings from the medical device...</p>
                                        <div className='stage-btn-container1'>
                                            <button className="cancel-btn" onClick={closePopup}>Close</button>
                                            <button className="submit-btn" onClick={takePhoto}>Take Photo</button>
                                        </div>
                                    </>
                                }

                                {popupStage === 'photoTaken' && (
                                    <>
                                        <p>Please make sure the photo is clear before analyzing...</p>
                                        <div className='stage-btn-container1'>
                                            <button className="cancel-btn" onClick={() => { setImageData(null); setPopupStage('initial'); }}>Retake</button>
                                            <button className="submit-btn" onClick={analyzeImage}>Analyze</button>
                                        </div>
                                    </>
                                )}
                                {popupStage === 'analysisDone' && (
                                    <>
                                        <div className="image-results-container">
                                            {(() => {
                                                try {
                                                    console.log("========== Analysis Results : " + JSON.stringify(analysisResult) + " ==========")
                                                    // Check if the analysisResult is a string indicating an error message
                                                    if (typeof analysisResult === 'string' && !analysisResult.startsWith('{')) {
                                                        return <p className="error-message">{analysisResult}</p>;
                                                    }

                                                    // Parse the analysis result if it's a JSON string
                                                    const resultObject = typeof analysisResult === 'string' ? JSON.parse(analysisResult) : analysisResult;

                                                    const renderResults = (obj) => {
                                                        console.log("========== ResultsObj : " + JSON.stringify(obj) + " ==========")
                                                        return Object.entries(obj).map(([key, value]) => {
                                                            if (typeof value === 'object' && 'value' in value && 'unit' in value) {
                                                                return (
                                                                    <p key={key}>
                                                                        {value.scan}: {value.value} {value.unit}
                                                                    </p>
                                                                );
                                                            } else if (typeof value === 'object') {
                                                                return (
                                                                    <div key={key}>
                                                                        {renderResults(value)}
                                                                    </div>
                                                                );
                                                            }
                                                            return (
                                                                <p key={key}>
                                                                    Results: {value || 'N/A'}
                                                                </p>
                                                            );
                                                        });
                                                    };

                                                    return renderResults(resultObject);
                                                } catch (error) {
                                                    console.error("Error parsing analysis result:", error);
                                                    return <p>Error displaying results</p>;
                                                }
                                            })()}
                                            <div className='results-stage-btn-container'>
                                                <button className="cancel-btn" onClick={() => { setImageData(null); setPopupStage('initial'); }}>Retake</button>
                                                <button className="submit-btn" onClick={() => setPopupStage('editResult')}>Edit Result</button>
                                                <button className="submit-btn" onClick={handleConfirm}>Confirm</button>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {popupStage === 'editResult' && (
                                    <div className='edit-container'>
                                            <p>Edit the result from the scan...</p>
                                        {(() => {
                                            try {
                                                // Parse the analysis result if it's a JSON string
                                                const resultObject = typeof analysisResult === 'string' ? JSON.parse(analysisResult) : analysisResult;
                                                console.log(" ========== resultObj : " + JSON.stringify(resultObject) + "  ============")
                                               
                                                //const handleInputChange = (key, newValue) => {
                                                //    setAnalysisResult(prevResult => {
                                                //        resultObject.ai = 0;
                                                //        resultObject.ai_value = resultObject["value"].value;
                                                //        
                                                //        const updatedResult = { ...prevResult };
                                                //        console.log("========== Updated result: " + JSON.stringify(updatedResult) + " ========== ")
                                                //        updatedResult[key].value = newValue;
                                                //        return updatedResult;
                                                //    });
                                                //};


                                                const handleInputChange = (key, newValue) => {
                                                    setAnalysisResult(prevResult => {
                                                        const updatedResult = { ...prevResult };
                                                
                                                        // Ensure the key exists and has the expected structure before modifying it
                                                        if (updatedResult[key]) {
                                                            if (updatedResult[key].hasOwnProperty('value')) {
                                                                // Check if ai_value is null and if so, set it to the current value before the edit
                                                                if (updatedResult[key].ai_value === null) {
                                                                    updatedResult[key].ai_value = updatedResult[key].value;
                                                                }
                                                
                                                                // Now update the value with the new input
                                                                updatedResult[key].value = newValue;
                                                            } else {
                                                                console.error(`The key "${key}" does not have a 'value' property.`);
                                                            }
                                                        } else {
                                                            console.error(`Key "${key}" does not exist in the result object.`);
                                                        }
                                                
                                                        // Set ai to 0 because it's now been edited
                                                        if (updatedResult.hasOwnProperty('ai')) {
                                                            updatedResult.ai = 0;
                                                        }
                                                
                                                        console.log("========== Updated result: " + JSON.stringify(updatedResult) + " ========== ");
                                                        return updatedResult;
                                                    });
                                                };
                                                

                                                const renderEditFields = (obj) => {
                                                    return Object.entries(obj).map(([key, value]) => {
                                                        if (typeof value === 'object' && value !== null && 'value' in value && 'unit' in value) {
                                                            return (
                                                                <div key={key} className="edit-field">
                                                                    
                                                                    <label>
                                                                        {value.scan} :
                                                                        <input
                                                                            type="text"
                                                                            value={value.value}
                                                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                                                        />
                                                                        {value.unit}
                                                                    </label>
                                                                </div>
                                                            );
                                                        } else if (typeof value === 'object' && value !== null) {
                                                            return (
                                                                <div key={key}>
                                                                    <strong>Results :</strong>
                                                                    {renderEditFields(value)}
                                                                </div>
                                                            );
                                                        }
                                                        return (
                                                            <p key={key}>
                                                                {key}: {value || 'N/A'}
                                                            </p>
                                                        );
                                                    });
                                                };

                                                return renderEditFields(resultObject);
                                            } catch (error) {
                                                console.error("Error parsing analysis result:", error);
                                                return <p>Error displaying results</p>;
                                            }
                                        })()}
                                        <button className='confirm-edit-btn' onClick={handleConfirm}>Confirm</button>
                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                </div>
            )}

            {isSubmitting && (
                <div className="popup">
                    <div className="popup-submit">
                        <img
                            className='submit-popup-logo'
                            src="http://medescreen.co.za/wp-content/uploads/2023/09/Medescreen-Logo-Green.png"
                            alt="Medescreen Logo"
                        />
                        {submitStatus === 'loading' && 
                        (
                        <div className='submit-load'>
                            <p>Submitting your data, please wait...</p>
                            <div className='loading-container'>
                                <img src='http://medescreen.co.za/wp-content/uploads/2023/09/cropped-MedFavicon.png' className='loader' alt='loader'/>
                            </div>
                        </div>
                        )}
                        {submitStatus === 'success' && 
                        (
                        <div className='submit-success'>
                            <p>Success! Redirecting...</p>
                            <div className='success-animation-container'>
                                <img className="check" src={checkMark} alt='check'/>
                                <img className="check" src={rightArrow} alt='check'/>
                            </div>
                        </div>
                        )}
                        {submitStatus === 'error' && 
                        (
                            <div className='submit-error'>
                                <p>Oh no,</p>
                                <p>Something went wrong...</p>
                                <p>Please try again.</p>
                                <button className='try-again-btn' onClick={handleSubmit}>Try Again</button>
                            </div>
                        )}
                        {submitStatus === 'failed' && 
                        (
                            <div className='submit-failed'>
                                <p>Error not resolved,</p>
                                <p>Please redo the test.</p>
                                <button onClick={() => {setCurrentSection('questionnaire'); setSubmitStatus(null); setIsSubmitting(false);}} className='redo-btn'>Redo Test</button>
                            </div>
                        )}
                    </div>
                </div>
            )}


            <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
        </div>
    );
}

export default MedicalTestPage;
